.servicesHero_section
{
    width: 100%;
    height: auto;
}

.servicesHero_area
{
    width: 100%;
    height: 137px;
    background-color:#E7E9EE;
    display: flex;
    align-items: center;
    padding: 23px;
}

.servicesHero_area h1
{
    font-weight: 700;
    font-size: 35px;
    color: #2029BD;
}


@media  (max-width:660px) {
    .servicesHero_area h1 {
        font-size: 22px!important;
    }
}