
.footer_wrapper
{
    width: 100%;
    height: auto;
    margin-top: 50px;
    background-color: #1d1d26;
}

.inner_sec_container
{
    max-width: 1100px;
    height: auto;
    margin: 0 auto;
}

.footer_top_section
{
    width: 100%;
    height: auto;
}

.footer_content_section
{
    width: 100%;
    height: auto;
    padding: 60px 0;
}

.footer_content_area
{
    width: 100%;
    height: auto;
}

.footer_content_area span
{
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 3px;
    color: #737380;
}

.footer_content_area h2
{
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    color: #fff;
    margin: 10px 0;
}

.address_heading
{
    font-size: 20px!important;
    margin: 5px 0!important;
    margin-bottom: 0 !important;
}

.footer_content_area a
{
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
}

.footer_content_area a:hover
{
    color: #2029bd;
}

.footer_content_area p
{
    font-size: 15px;
    line-height: 27px;
    font-weight: 400;
    color: #737380;
    margin: 10px 0;
}

.footer_content_area .email_link
{
    font-size: 15px;
    line-height: 27px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
}

.footer_content_area .email_link:hover
{
    color: #2029bd;
    text-decoration: underline;
}

.footer_social_icon_section
{
    width: 100%;
    height: auto;
}

.social_icon
{
    color: #fff;
    margin-right: 0.5rem;
    font-size: 18px;
}

.social_icon:hover
{
    color: #2029bd;
    cursor: pointer;
}

.footer_bottom_content_section
{
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.footer_bottom_content_left_section
{
    width: 100%;
    height: auto;
}

.footer_bottom_content_left_section p
{
    color: #65666C;
}

.footer_bottom_content_right_section
{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 30px;
}

.footer_bottom_content_right_section ul
{
    display: flex;
    padding: 0;
    margin: 0;
}

.footer_bottom_content_right_section ul li
{
    list-style: none;
}

.footer_bottom_content_right_section ul li a
{
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgba(255, 255, 255, .1);
    color: #65666C;
    text-decoration: none;
}

.footer_bottom_content_right_section ul li a:hover
{
    color: #fff;
}

.footer_wrapper hr
{
    color: #65666C!important;
}

.upArrow_icon
{
    color: #65666C;
    cursor: pointer;
    font-size: 20px;
    border: 1px solid #65666C;
}

.upArrow_icon:hover
{
    color: #fff;
    border: 1px solid #fff;
}

.active
{
    color: #fff!important;
}

@media  (max-width:660px) 
{
    .footer_content_section
    {
        padding: 40px 0!important;
    }
   
    .footer_content_area span
    {
        font-size: 11px!important;
        line-height: 15px!important;
    }

    .footer_content_area h2
    {
        font-size: 28px!important;
        line-height: 37px!important;
    }

    .footer_content_area h2 a
    {
        font-size: 28px!important;
        line-height: 37px!important;
    }

    .footer_content_area p
    {
        font-size: 13px!important;
        line-height: 24px!important;
    }

    .footer_content_area .address_heading    
    {
        font-size: 18px!important;
        margin: 5px 0!important;
        margin-bottom: 0 !important;
    }

    .row_mb
    {
        flex-direction: column-reverse;
    }

    .footer_bottom_content_section
    {
        padding: 0;
    }

    .footer_bottom_content_right_section ul li a
    {
        font-size: 14px;
    }
    
    .footer_bottom_content_left_section p
    {
        text-align: center;
    }

    .footer_bottom_content_right_section
    {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) and (max-width:1023px) 
{
    .footer_content_area span
    {
        font-size: 11px!important;
    }

    .footer_content_area a 
    {
        font-size: 30px!important;
        line-height: 38px!important;
    }

    .footer_content_area p 
    {
        font-size: 12px!important;
        line-height: 24px!important;
    }

    .footer_content_area .email_link
    {
        font-size: 12px!important;
        line-height: 24px!important;
    }
}
