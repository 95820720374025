body
{
    font-family: 'Poppins', sans-serif!important;
}

h1,h2,h3,h4,h5,h6,p
{
    padding: 0;
    margin: 0;
}

@media  (max-width:660px) 
{
    .inner_sec_container
    {
        padding: 0 10px!important;
    }
}

@media (min-width: 768px) and (max-width:1050px) 
{
    .inner_sec_container
    {
        padding: 0 20px!important;
    }
}