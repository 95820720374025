
.services_card_section
{
    width: 100%;
    height: 100%;
    background-color: #000;
    padding: 20px;
}

.services_card_content_area
{
    width: 100%;
    height: auto;
}

.services_card_content_area h2
{
    font-weight: 700;
    font-size: 20px;
    line-height: 165%;
    color: #FFFFFF;
}

.services_card_content_area p
{
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    color: #FFFFFF;
}