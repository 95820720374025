.success {
    background-color: #faffe5;
    border: 1px solid #C3D284;
    padding: 10px;
    width: fit-content;
}

.contact_us_wrapper {
    width: 100%;
    height: auto;
}

.contact_us_content_section {
    width: 100%;
    height: auto;
}

.contact_us_content_area {
    width: 100%;
    height: auto;
}

.contact_us_info_section {
    width: 100%;
    height: auto;
}


.contact_us_info_section h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    color: #2029BD;
}

.contact_us_info_section p {
    font-size: 15px;
    line-height: 27px;
    font-weight: 400;
    color: #747880;
}

.contact_us_info_section p a {
    color: #2029BD;
    text-decoration: none;
}

.contact_us_social_icons {
    width: 100%;
    height: auto;

}

.contact_us_social_icons_area {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact_us_social_icons .social_icon {
    font-size: 24px;
    color: #2029BD;
}

.contact_us_social_icons h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    color: #2029BD;
}

.contact_us_form_section {
    width: 100%;
    height: auto;
}

.contact_us_form_area {
    width: 100%;
    height: auto;
}

.contact_us_form_area h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    color: #000000;
}

.contact_us_form_area label {
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    color: #747880;
}

.contact_us_form_area input {
    width: 100%;
    border: 1px solid #E7E9EE;
    min-height: 55px;
    padding: 10px;
}

.contact_us_form_area textarea {
    width: 100%;
    border: 1px solid #E7E9EE;
    padding: 10px;
}

.contact_us_form_area .submit_btn {
    border: 0;
    background-color: #2029BD;
    color: #fff;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
}


/* hero section */
.small_hero_section {
    width: 100%;
    height: 292px;
    display: flex;
    justify-content: space-between;
    background-color: #e7e9ee;
    min-height: 137px;
}

.small_hero_left_section {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
}

.small_hero_left_content_section {
    width: 100%;
    height: auto;
}

.small_hero_left_content_section h1 {
    text-align: center;
    margin: 0;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #2029BD;
}

.small_hero_right_section {
    width: 55%;
    height: 100%;
}

.small_hero_right_section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.linkedIn {
    font-size: 28px !important;
}




@media (max-width:660px) {
    .contact_us_wrapper .small_hero_right_section {
        width: 100% !important;
        height: 70% !important;
    }

    .contact_us_wrapper .small_hero_left_section {
        width: 100% !important;
        height: 28% !important;
    }

    .contact_us_info_section p {
        line-height: 24px !important;
    }

    .contact_us_form_area h2 {
        padding-left: 0 !important;
    }
}



@media (min-width: 768px) and (max-width:1023px) {}