
.header_wrapper
{
    width: 100%;
    height: auto;
}

.header_section
{
    width: 100%;
    height: auto;
    padding: 0 10px;
    position: relative;
}

.header_logo_section
{
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
}

.header_logo_section img
{
    width: 150px;
    object-fit: cover;
}

.header_nav_section
{
    width: 100%;
    height: auto;
}

.header_nav_area
{
    width: 100%;
    height: 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.header_nav_area ul
{
    display: flex;
    gap: 30px;
    padding: 0;
    margin: 0;
}

.header_nav_area ul li
{
    list-style: none;
}

.header_nav_area ul li a
{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #333;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-decoration: none;
}

.header_nav_area ul li a:hover
{
    color: #2029bd;
}

.mobile_menu_hamburger_section
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hamburger_icon
{
    font-size: 22px;
    color: #333;
    display: none;
}

.mobile_menu_section
{
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
}

.mobile_menu_layer_section
{
    width: 40%;
    height: 100%;
    background-color: rgba(0,0,0,.55);
    transform: translateX(-100%);
    transition: 5s ease-in-out;
}

.layer_show
{
    transform: translateX(0);
}

.mobile_menu_nav_section
{
    width: 60%;
    height: 100%;
    background-color: #333;
    position: relative;
    padding: 60px 35px;
}

.mobile_menu_nav_section ul
{
    padding: 0;
    margin: 0;
}

.mobile_menu_nav_section ul li
{
    list-style: none;
    margin: 10px 0;
}

.mobile_menu_nav_section ul li a
{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #a6a6a6;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-decoration: none;
}

.mobile_menu_nav_section ul li a:hover
{
    color: #fff;
}

.cross_icon
{
    font-size: 22px;
    color: #fff;
    position: absolute;
    top: 14px;
    right: 21px;
}

.mobile_menu_nav_area
{
    width: 100%;
    height: auto;
}

.mobile_menu_call_now_btn
{
    width: 100%;
    height: auto;
    margin: 15px 0;
}

.mobile_menu_call_now_btn .call_now_btn
{
    color: #fff;
    background-color: #2029bd;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;
    padding: 10px 20px;
}


.mobile_menu_nav_section ul li .active
{
    color: #fff!important;
}

.buy_now_section
{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.buy_now_btn
{
    color: #626262;
    background-color: #dbdddf;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    padding: 18px 30px;
    display: inline-block;
    text-decoration: none;
}


.buy_now_btn:hover
{
    filter: brightness(.9);
    color: #626262;
}

.right_arrow
{
    margin-bottom: 3px;
}

.active
{
    color: #2029bd!important;
    
}

.sticky
{
    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background: #fff;
    opacity: .97;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
    z-index: 10000;
}


.sticky .header_logo_section
{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.sticky .header_logo_section img
{
    width: 120px;
    object-fit: cover;
}

.sticky .header_nav_area
{
    width: 100%;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.sticky .buy_now_section
{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sticky .buy_now_btn
{
    color: #626262;
    background-color: #dbdddf;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    padding: 14px 26px;
}




@media  (max-width:660px) {
    .header_nav_section
    {
        display: none!important;
    }

    .buy_now_section
    {
        display: none!important;
    }

    .sticky .buy_now_section
    {
        display: none!important;
    }
    
    .mobile_menu_hamburger_section .hamburger_icon
    {
        display: block!important;
    }
}

@media (min-width: 768px) and (max-width:1023px) 
{
    
    .header_logo_section img
    {
        width: 110px!important;
    }
    
    .buy_now_btn
    {
        padding: 12px 21px!important;
        font-size: 10px!important;
    }

    .header_nav_area ul
    {
        gap: 19px!important;
    }

    .header_nav_area ul li a
    {
        font-size: 10px!important;
    }
}