
.aboutus_wrapper
{
    width: 100%;
    height: auto;
}

.about_us_content_section
{
    width: 100%;
    height: auto;
    margin: 100px 0 40px 0;
}

.inner_sec_container
{
    max-width: 1100px;
    height: auto;
    margin: 0 auto;
}

.about_us_content_area
{
    width: 100%;
    height: auto;
}

.our_mission_section
{
    width: 100%;
    height: auto;
}

.our_mission_section h2
{
    font-weight: 500;
    font-size: 25px;
    line-height: 120%;
    color: #000;
}

.our_mission_section p
{
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    color: #747880;
}

.our_mission_area
{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 0.6rem;
}

.our_mission_area .check_icon
{
    font-size: 22px;
    color: #BDC7DF;
}

.our_mission_area span
{
    padding-left: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    color: #747880;
}

.about_features_section
{
    width: 100%;
    height: 100%;
}

.about_features_left_section
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.about_features_left_section img
{
    width: 100%;
    object-fit: cover;
}

.about_features_right_section
{
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
}

.about_features_right_area
{
    width: 100%;
    height: auto;
}
.about_features_right_area h2
{
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    color: #000;
}

.row
{
    margin: 0!important;
}

.aboutus_wrapper .small_hero_section .small_hero_right_section img
{
    width: fit-content;
    height: 380px;
    object-fit: contain;
}

.aboutus_wrapper .small_hero_section .small_hero_right_section
{
    display: flex;
    justify-content: flex-end;
}

@media  (max-width:660px) 
{
    .aboutus_wrapper .small_hero_section 
    {
        flex-direction: column!important;
        padding: 27px 0px!important;
        overflow-x: clip!important;
    }
    .aboutus_wrapper .small_hero_left_content_section h1
    {
        text-align: start;
        font-size: 22px!important;
        line-height: 26px!important;
        margin-left: 20px!important;
    }
    .aboutus_wrapper .small_hero_section .small_hero_right_section img
    {
        width: 426px!important;
        height: 347px!important;
        margin-right: -42px!important;
    }
    .small_hero_left_section
    {
        width: 100%!important;
    }
    .small_hero_right_section
    {
        width: 100%!important;
        height: auto!important;
    }
    .our_mission_section
    {
        padding: 10px!important;
    }
    .our_mission_section h2
    {
        font-size: 22px!important;
        line-height: 26px!important;
        margin-bottom: 1.5rem!important;
    }

    .our_mission_section p 
    {
        font-size: 14px!important;
        line-height: 23px!important;
    }

    .our_mission_area
    {
        grid-template-columns: 16px 1fr!important;
        gap: 0.4rem!important;
    }

    .our_mission_area .check_icon
    {
        font-size: 18px!important;
        margin-top: 4px!important;
    }

    .our_mission_area span
    {
        font-size: 14px!important;
        line-height: 23px!important;
    }

    .our_mission_card_area h3
    {
        font-size: 22px!important;
        line-height: 32px!important;
    }

    .about_features_right_section
    {
        padding: 0px!important;
    }
    .about_features_right_area .our_mission_area span {
        font-size: 15px!important;
        line-height: 24px!important;
    }
}

@media (min-width: 768px) and (max-width:1023px) 


{
    .aboutus_wrapper .small_hero_section .small_hero_right_section img
    {
        width: 455px!important;
        height: 444px!important;
    }
    .our_mission_card_area
    {
        padding: 30px!important;
    }

    .press_material_btn a
    {
        padding: 12px 24px!important;
    }
    .about_features_left_section img {
        width: 400px;
        display: block;
        margin: 0 auto;
    }
}