.home_wrapper {
    width: 100%;
    height: auto;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    /* 16:9 aspect ratio, adjust as needed */
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    background-color: #000;
}

.home_section {
    width: 100%;
    height: auto;
}

.hero_section {
    width: 100%;
    height: 360px;
    display: flex;
 
}


.hero_left_section {
    width: 50%;
    height: auto;
    padding: 30px 0 30px 30px;
    display: flex;
    align-items: center;
}

.hero_left_content_section {
    width: 100%;
    height: auto;

}

.hero_left_content_section h1 {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #fff;
    margin-top: 10px;
}

.hero_left_content_section p {
    margin: 25px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-top: 10px;
}

.hero_section_btn_section {
    width: 100%;
    height: auto;
    margin: 40px 0;
}

.who_we_are_btn {
    background-color: rgb(32, 41, 189);
    font-weight: 600;
    font-size: 15px;
    border: 0;
    padding: 10px 15px;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.who_we_are_btn:hover {
    filter: brightness(0.9);
    color: rgb(255, 255, 255);
}

.our_solution_btn {
    font-weight: 600;
    font-size: 15px;
    border: 0;
    padding: 10px 15px;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.our_solution_btn:hover {
    color: rgb(0, 0, 0);
}

.hero_right_section {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.hero_right_img_Section {
    width: fit-content;
    height: auto;
}

.hero_right_img_Section img {
    width: 100%;
    height: 330px;
    object-fit: contain;
}

.home_page_content_section {
    width: 100%;
    height: auto;
}

.our_strategy_section {
    width: 100%;
    height: auto;
    margin-top: 7rem;
}

.our_strategy_content_section {
    width: 100%;
    height: auto;

}

.our_strategy_content_section h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #000;
    margin-bottom: 1.5rem;
    text-align: center;
}

.our_products_section {
    width: 100%;
    height: auto;
    margin-top: 40px;
    position: relative;
}

.our_product_cards_section {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
}

.our_product_img_section {
    width: 100%;
    height: auto;
    padding: 20px 0;
    height: 180px;
    align-items: center;
    display: flex;
}

.our_product_img_section img {
    width: fit-content;
    height: fit-content;
    object-fit: contain;
    display: block;
    margin: 0 auto;
}

.our_product_cards_section h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    color: #000;
    margin: 1rem 0;
}

.our_product_cards_section p {
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 3px;
    color: #8b9099;
}

.our_product_cards-area {
    width: 100%;
    height: auto;
}

.our_product_features_section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.our_fetaure_content_section {
    width: fit-content;
    height: auto;
}

.our_product_features_area {
    width: 100%;
    height: auto;
    margin: 2px 0;
    display: flex;
    align-items: center;
}

.our_product_features_area .check_icon {
    font-size: 22px;
    color: #BDC7DF;
}

.our_product_features_area span {
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    color: #747880;
    margin-left: 10px;
    margin-top: 3px;
}

.what_we_can_do_section {
    width: 100%;
    height: auto;
    margin-top: 5rem;
}

.what_we_can_do_area {
    width: 100%;
    height: auto;
}

.what_we_can_do_area h3 {
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #000000;
    margin-bottom: 1.5rem;
}

.what_we_can_do_area .services_card_section .services_card_area {
    padding: 25px !important;
}

.what_we_can_do_area .services_card_section .services_card_area .services_card_content_section {
    width: 100% !important;
}

.home_page_content_section .quote_now_section {
    margin-top: 7rem;
}

.hero_mobile_img {
    display: none;
}



@media (max-width:660px) {
    .our_product_img_section {
        align-items: flex-start !important;
        height: unset;
    }

    .our_product_img_section img {
        height: unset;
        vertical-align: top;
    }

    .hero_section {
        display: block !important;
        position: relative !important;
        height: 70vh;
        padding: calc(2vw + 5px);

    }

    .hero_left_section {
        width: 93% !important;
        padding: 15px 0 15px 15px !important;
        position: relative !important;
        z-index: 100 !important;
    }

    .hero_left_content_section h1 {
        font-size: 22px !important;
        line-height: 26px !important;
    }

    .hero_left_content_section p {
        margin: 18px 0 !important;
        font-size: 15px !important;
        line-height: 22px !important;
      
    }

    .hero_section_btn_section {
        margin: 0 !important;
        margin-top: 55px !important;
    }

    .hero_right_section {
        display: none !important;
    }

    .hero_mobile_img {
        display: block !important;
        position: absolute !important;
        top: 12vh !important;
        right: 0 !important;
        width: auto !important;
        height: 50vh;
        max-height: 100%;
    }

    .our_strategy_content_section h2 {
        font-size: 25px !important;
        line-height: 30px !important;
    }

    .our_product_cards_section {
        display: flex !important;
    }

    .our_product_img_section {
        width: 22% !important;
    }

    .our_product_cards-area {
        width: 88% !important;
    }

    .our_product_img_section img {
        width: 50px;
        margin: 0;
    }

    .our_product_features_section {
        width: 100% !important;
        display: block !important;
    }

    .our_product_cards-area h2 {
        font-size: 20px !important;
        line-height: 24px !important;
        text-align: start !important;
        margin-bottom: 0.7rem !important;
    }

    .our_product_features_area span {
        font-size: 14px !important;
        line-height: 23px !important;
    }

    .what_we_can_do_area h3 {
        font-size: 25px !important;
        line-height: 30px !important;
    }

    .our_strategy_section {
        margin-top: 3rem !important;
    }

    .our_products_section {
        margin-top: 25px !important;
    }

    .our_product_cards_section {
        margin-bottom: 25px !important;
    }

    .what_we_can_do_section {
        margin-top: 1rem !important;
    }

    .our_product_features_area .check_icon {
        font-size: 18px !important;
        margin-top: 5px !important;
    }
}

@media (max-width:333px) {
    .hero_section_btn_section {
        display: flex;
        flex-direction: column;
    }

    .hero_left_content_section p {
        margin-bottom: 20px !important;
    }

    .our_product_img_section img {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .hero_left_content_section h1 {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .hero_left_content_section p {
        margin: 20px 0 !important;
        font-size: 18px !important;
        line-height: 30px !important;
    }

    .hero_section_btn_section {
        margin: 25px 0 !important;
    }

    .who_we_are_btn {
        font-size: 13px !important;
    }

    .our_solution_btn {
        font-size: 13px !important;
    }

    .our_strategy_content_section h2 {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .our_product_cards_section {
        margin-bottom: 40px !important;
    }

    .our_product_cards-area h2 {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    .what_we_can_do_section {
        margin-top: 2rem !important;
    }

    .what_we_can_do_area h3 {
        font-size: 28px !important;
        line-height: 35px;
    }
}