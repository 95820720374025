
.small_hero_section
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    background-color: #E7E9EE;
    min-height: 137px
    ;
}

.small_hero_left_section
{
    width: 50%;
    height: auto;
    background-color: #e7e9ee;
    display: flex;
    align-items: center;
}

.small_hero_left_content_section
{
    width: 100%;
    height: auto;
}

.small_hero_left_content_section h1
{
    text-align: center;
    margin: 0;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #2029BD;
}

.small_hero_right_section
{
    width: 55%;
    height: 100%;
}

.small_hero_right_section img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media  (max-width:660px) 
{
    .small_hero_left_content_section p
    {
        width: 40px!important;
        height: 40px!important;
        font-size: 14px!important;
    }
    
    .small_hero_left_content_section h1
    {
        font-size: 38px!important;
        line-height: 47px!important;
    }
}

@media (min-width: 768px) and (max-width:1023px) 
{
    .small_hero_left_content_section p 
    {
        width: 40px!important;
        height: 40px!important;
        font-size: 12px!important;
    }

    .small_hero_left_content_section h1
    {
        font-size: 40px!important;
        line-height: 50px!important;
    }
}