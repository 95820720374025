.mobileAppDev_wrapper
{
    width: 100%;
    height: auto;
}

.mobileAppDev_section
{
    width: 100%;
    height: auto;
}

.mobileAppDev_section h1
{
    font-weight: 500;
    font-size: 25px;
    line-height: 165%;
    color: #000000;
    margin-left: 0.7rem;
    margin-bottom: 4rem;
}

.mobileAppDev_desc_area
{
    width: 100%;
    height: auto;
    padding: 0.7rem;
}

.mobileAppDev_desc_area p
{
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    color: #747880;
}

.mobileAppDev_desc_area h3
{
    font-weight: 700;
    font-size: 15px;
    line-height: 24.75px;
    color: #747880;
}

@media  (max-width:660px) 
{
    .mobileAppDev_section h1 {
        font-size: 18px!important;
        line-height: 150%!important;
        margin-bottom: 2rem!important;
    }
}