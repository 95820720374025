
.our_business_model_section
{
    width: 100%;
    height: auto;
    margin: 50px 0;
}

.our_business_model_left_section
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.our_business_model_left_section img
{
    width: 370px;
    height: 328px;
    object-fit: contain;
}

.our_business_model_right_section
{
    width: 100%;
    height: auto;
    margin-left: 20px;
}

.our_business_model_right_section h2
{
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: #000;
    margin-bottom: 0.5rem;
}

.our_business_model_right_section p
{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #747880;
    text-align: justify;
}

.our_business_testimonal_area
{
    width: 100%;
    height: auto;
    padding-left: 50px;
    position: relative;
}

.quote_icon
{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    color: #747880;
}

.our_business_testimonal_area h3
{
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    letter-spacing: 0px;    
    color: #747880;
}

.our_business_testimonal_area p
{
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 3px;
    color: #8b9099;
}

.products_btn
{
    font-weight: 600;
    font-size: 15px;
    border: 0;
    background-color: rgb(32, 41, 189);
    letter-spacing: 2px;
}

.products_btn a
{
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: inline-block;
    padding: 10px 15px;
}

.products_btn a:hover
{
    color: rgb(255, 255, 255);
}

.products_btn:hover
{
    filter: brightness(0.9);
}

.contactus_btn
{
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #e7e9ee;
    background-color: transparent;
    margin-left: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.contactus_btn a
{
    color: #000;
    text-decoration: none;
    display: inline-block;
    padding: 15px 30px;
}

.contactus_btn a:hover
{
    color: #000;
}

.contactus_btn:hover
{
    color: #000000;
    background-color: #e7e9ee;
}

.inner_sec_container
{
    max-width: 1100px;
    height: auto;
    margin: 0 auto;
}

@media  (max-width:660px) {
 
    .our_business_model_left_section
    {
        justify-content: center!important;
    }

    .our_business_model_left_section img
    {
        height: 245px!important;
    }
    .our_business_model_right_section h2
    {
        font-size: 25px!important;
        line-height: 30px!important;
        text-align: center!important;
        margin-top: 2.5rem!important;
        margin-bottom: 1.5rem!important;
    }

    .our_business_model_right_section p
    {
        font-size: 14px!important;
        line-height: 21px!important;
    }

    .our_business_testimonal_area h3
    {
        font-size: 14px!important;
        line-height: 21px!important;
    }

    .our_business_testimonal_area p
    {
        font-size: 12px!important;
        line-height: 21px!important;
    }

  
    .products_btn a
    {
        padding: 8px 16px!important;
        font-size: 13px;
    }
    

    .our_business_model_right_section
    {
        margin: 0!important;
    }
}

@media (min-width: 768px) and (max-width:1023px) 
{
    
    .our_business_model_left_section img
    {
        width: 280px!important;
        height: 260px!important;
    }
    .our_business_model_right_section h2
    {
        font-size: 28px!important;
        line-height: 35px!important;
    }

    .our_business_model_right_section p
    {
        font-size: 13px!important;
        line-height: 19px!important;
    }

    .our_business_model_right_section h3
    {
        font-size: 14px!important;
        line-height: 19px!important;    
    }

    .our_business_testimonal_area h3
    {
        font-size: 14px!important;
        line-height: 19px!important;
    }

    .our_business_testimonal_area p
    {
        font-size: 12px!important;
        line-height: 14px!important;
    }

    .products_btn
    {
        font-size: 12px!important;
    }

    .products_btn a
    {
        padding: 8px 12px!important;
    }

}