
.solutions_wrapper
{
    width: 100%;
    height: auto;
}

.services_content_section
{
    width: 100%;
    height: auto;
    margin: 80px 0;
}

.services_content_area
{
    width: 100%;
    height: auto;
}

.services_content_area h2
{
    font-weight: 500;
    font-size: 25px;
    color: #000000;
    line-height: 30px;
}

.services_content_area p
{
    font-weight: 500;
    font-size: 15px;
    color: #747880;
    line-height: 18px;
}

.contact_link
{
    color: #2029BD;
    text-decoration: none;
    font-weight: 600;
}

.services_card_section
{
    width: 100%;
    height: auto;
    /* background-color: lightblue; */
}


.services_card_area
{
    width: 100%;
    height: 100%;
    border: 1px solid #E7E9EE;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
}

.services_card_area:hover
{
    border: 1px solid #2029BD;
    cursor: pointer;
}

.services_icon_section
{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services_card_content_section
{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.services_card_content_area
{
    width: 100%;
    height: auto;
}

.services_card_content_area h3
{
    font-weight: 700;
    font-size: 22px;    
    color: #000000;
}

.services_card_content_area p
{
    font-weight: 400;
    font-size: 14px;
    color: #747880;
    margin: 0;
}

.services_card_section a
{
    text-decoration: none;
}

.quote_now_section
{
    width: 93%;
    height: 248px;
    background-color: #EFF2F9;
    display: flex;
    justify-content: space-between;
}

.quote_now_img_section
{
    width: 35%;
    height: 100%;
    /* background-color: #2029BD; */
    display: flex;
    justify-content: center;
}

.quote_now_img_section img
{
    width: 257px;
    height: 250px;
    object-fit: contain;
    margin-top: -56px;
}

.quote_now_content_section
{
    width: 65%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}

.quote_now_content_area
{
    width: 100%;
    height: auto;
    /* background-color: red; */
}

.quote_now_content_area h3
{
    font-weight: 700;
    font-size: 25px;
    color: #2029BD;  
    margin-bottom: 15px;
}

.quote_now_content_area p
{
    font-weight: 400;
    font-size: 15px;
    color: #000000; 
    width: 90%;
}

.quote_now_button_section
{
    width: 88%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;
}

.get_quote_btn
{
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    padding: 7px 14px;
    text-transform: capitalize;
    background-color: #2029BD;
    border: 0;
}

@media  (max-width:660px) {

    .services_content_section 
    {
        margin-top: 40px!important;
        padding: 10px!important;
    }
    .small_hero_section
    {
        flex-direction: column!important;
    }
    .small_hero_left_content_section h1
    {
        font-size: 22px!important;
        margin-left: 20px!important;
        text-align: start!important;
    }
    .services_wrapper .small_hero_left_section
    {
        height: 30%!important;
    }
    .services_wrapper .small_hero_right_section 
    {
        width: 100%!important;
        height: 68%!important;
    }
    .services_content_area h2 
    {
        font-size: 20px!important;
        line-height: 24px!important;
    }
    .services_content_area p
    {
        font-size: 14px!important;
        line-height: 23px!important;
    }
    .services_card_content_area h3
    {
        font-size: 18px!important;
        line-height: 21px!important;
        margin-bottom: 1rem!important;
        text-align: start!important;
    }
    .services_card_content_area h3
    {
        font-size: 18px!important;
        line-height: 21px!important;
        margin-bottom: 1rem!important;
        text-align: start!important;
    }
    .services_card_section .services_card_area {
        padding: 20px!important;
    }
    .services_icon_section 
    {
        width: 20%!important;
        display: block!important;
    }
    .services_icon_section img
    {
        width: 46px!important;
    }
    .services_card_content_section 
    {
        width: 80%!important;
    }
    .quote_now_section {
        height: auto!important;
        flex-direction: column!important;
        padding: 22px!important;
    }
    .quote_now_img_section
    {
        height: auto!important;
        margin-left: 20px!important;
        display: block!important;
    }
    .quote_now_img_section img {
        width: 126px!important;
        height: 157px!important;
        margin-top: -95px!important;
    }
    .quote_now_content_section {
        width: 100%!important;
        height: auto!important;
        margin-top: 20px!important;
    }
    .quote_now_content_area h3 {        
        font-size: 20px!important;
        line-height: 24px!important;
    }
    .quote_now_content_area p {        
        font-size: 14px!important;
        width: 100%!important;
        line-height: 16px!important;
    }
    .quote_now_button_section
    {
        width: 100%!important;
    }
    .get_quote_btn
    {
        display: block!important;
        width: 100%!important;
    }
}

@media (min-width: 768px) and (max-width:1023px) 
{
  
    .services_card_content_area h3
    {
        font-size: 18px!important;
        line-height: 25px!important;
        text-align: start!important;
        margin-bottom: 1rem!important;
    }
    .what_we_can_do_area .services_card_section .services_card_area
    {
        padding: 15px!important;
    }
    .home_page_content_section .quote_now_section 
    {
        margin-top: 5rem!important;
    }
    .quote_now_img_section img 
    {
        width: 220px!important;
        height: 325px!important;
        margin-top: -53px!important;
    }
    .quote_now_content_area h3 
    {
        font-size: 22px!important;
        margin-bottom: 15px!important;
    }
    .quote_now_content_area p
    {
        font-size: 13px!important;
    }
    .quote_now_button_section
    {
        margin-top: 17px!important;
    }
}